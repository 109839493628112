define('apco-web/components/form-fields/color-field', ['exports', 'ember-form-for/components/form-fields/color-field'], function (exports, _colorField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _colorField.default;
    }
  });
});