define('apco-web/controllers/profile', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        currentUser: Ember.inject.service('current-user'),
        actions: {
            saveProfile: function saveProfile() {
                var user = this.get('currentUser.user');
                user.set('fullname', this.get('currentUser.user.fullname'));
                user.set('company', this.get('currentUser.user.company'));
                user.set('phone', this.get('currentUser.user.phone'));
                user.save();
                this.transitionToRoute('projects');
            }
        }
    });
});