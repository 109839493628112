define('apco-web/routes/projects', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        title: "Projects",
        model: function model() {
            return Ember.RSVP.hash({
                projects: this.get('store').findAll('project'),
                colors: this.get('store').findAll('color')
            });
        }
    });
});