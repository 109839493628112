define('apco-web/components/color-picker', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        pantoneFilter: '',
        customFile: '',
        actions: {
            pantoneFilterChanged: function pantoneFilterChanged() {
                this.get('doPantoneFilter')(this.get('pantoneFilter'));
            },
            colorChanged: function colorChanged(name, newValue) {
                this.sendAction('colorChanged', this.get('colorProperty'), newValue);
            },
            uploadImage: function uploadImage(file) {
                this.sendAction('uploadImage', this.get('colorProperty'), file);
            },
            deleteImage: function deleteImage(color) {
                this.sendAction('deleteImage', color);
            },
            closeColorTooltip: function closeColorTooltip(dropdown) {
                var _this = this;

                // cancel opening and closing a new tooltip
                if (this.openTimer) {
                    Ember.run.cancel(this.openTimer);
                    this.openTimer = null;
                }
                this.closeTimer = Ember.run.later(function () {
                    _this.closeTimer = null;
                    dropdown.actions.close();
                }, 300);
            },
            openColorTooltip: function openColorTooltip(dropdown) {
                var _this2 = this;

                if (this.closeTimer) {
                    // close the previous one before opening a new one
                    Ember.run.cancel(this.closeTimer);
                    this.closeTimer = null;
                    this.previousDropdown.actions.close();
                    dropdown.actions.close();
                }
                this.previousDropdown = dropdown;
                if (this.openTimer) {
                    // cancel timer to open a new tooltip, since it's a different tooltip
                    Ember.run.cancel(this.openTimer);
                    this.openTimer = null;
                }
                this.openTimer = Ember.run.later(function () {
                    _this2.openTimer = null;
                    dropdown.actions.open();
                }, 400);
            }
        }
    });
});