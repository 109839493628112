define('apco-web/router', ['exports', 'apco-web/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Router = Ember.Router.extend({
        location: _environment.default.locationType,
        rootURL: _environment.default.rootURL
    });

    Router.map(function () {
        this.route('login');
        this.route('signup');
        this.route('forgot-password');
        this.route('reset-password');
        this.route('profile');
        this.route('change-password');
        this.route('projects', { path: '/projects' }, function () {
            this.route('new', { path: '/new' });
            this.route('edit', { path: '/:project_id' });
        });
        this.route('concepts', { path: 'projects/:project_id/concepts' }, function () {
            this.route('new');
            this.route('edit', { path: '/:concept_id/edit' });
            this.route('edit-project', { path: '/:concept_id/edit-project' });
        });
        this.route('design', { path: 'projects/:project_id/concepts/:concept_id/design' }, function () {
            this.route('industry', { path: '/industry' });
            this.route('font', { path: '/font' });
            this.route('edit-concept', { path: '/edit-concept' });
        });
        this.route('export', { path: 'projects/:project_id/concepts/:concept_id/export' });
    });

    exports.default = Router;
});