define('apco-web/mirage/factories/concept', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberCliMirage.default.Factory.extend({
        name: function name(i) {
            if (i < 4) {
                return ['Welcome', 'Hello', 'Greetings', 'Hi'][i];
            }
            return 'Concept ' + i;
        },
        conceptTypeId: function conceptTypeId() {
            return _emberCliMirage.faker.random.number({ min: 1, max: 3 });
        },
        industryId: function industryId() {
            return _emberCliMirage.faker.random.number({ min: 1, max: 3 });
        },
        fontId: function fontId() {
            return _emberCliMirage.faker.random.number({ min: 1, max: 3 });
        },
        backerShapeId: function backerShapeId() {
            return _emberCliMirage.faker.random.number({ min: 1, max: 10 });
        },
        backerColoraId: function backerColoraId() {
            return _emberCliMirage.faker.random.number({ min: 1, max: 100 });
        },
        backerColorbId: function backerColorbId() {
            return _emberCliMirage.faker.random.number({ min: 1, max: 100 });
        },
        frameColorId: function frameColorId() {
            return _emberCliMirage.faker.random.number({ min: 1, max: 100 });
        },
        headerBgColorId: function headerBgColorId() {
            return _emberCliMirage.faker.random.number({ min: 1, max: 100 });
        },
        headerTextColorId: function headerTextColorId() {
            return _emberCliMirage.faker.random.number({ min: 1, max: 100 });
        },
        insertBgColorId: function insertBgColorId() {
            return _emberCliMirage.faker.random.number({ min: 1, max: 100 });
        },
        insertTextColorId: function insertTextColorId() {
            return _emberCliMirage.faker.random.number({ min: 1, max: 100 });
        },
        paperBgColorId: function paperBgColorId() {
            return _emberCliMirage.faker.random.number({ min: 1, max: 100 });
        },
        paperTextColorId: function paperTextColorId() {
            return _emberCliMirage.faker.random.number({ min: 1, max: 100 });
        },
        created_date: function created_date() {
            return [new Date(2018, 0, 5), new Date(2017, 8, 18), new Date(2017, 11, 3), new Date(2018, 0, 27)][Math.floor(Math.random() * 4)];
        }
    });
});