define('apco-web/routes/projects/new', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        title: "New Project",
        errorMessage: "",
        spinner: Ember.inject.service('spinner'),
        model: function model() {
            return {};
        },

        actions: {
            closeModal: function closeModal() {
                this.transitionTo('projects');
            },
            createProject: function createProject(data) {
                var _this = this;

                if (data.name.length == 0) {
                    this.controller.set('errorMessage', 'Please enter a project name.');
                    return;
                }
                this.get('spinner').show('project-spinner');
                var project = this.store.createRecord('project', {
                    name: data.name,
                    notes: '',
                    created_date: new Date()
                });
                project.save().then(function () {
                    return _this.transitionTo('projects');
                });
            }
        }
    });
});