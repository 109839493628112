define('apco-web/controllers/reset-password', ['exports', 'apco-web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    queryParams: ['token'],
    actions: {
      resetPassword: function resetPassword() {
        var self = this;

        var _getProperties = this.getProperties('password', 'confirmation'),
            password = _getProperties.password,
            confirmation = _getProperties.confirmation;

        if (password != confirmation) {
          self.set('errorMessage', 'Passwords don\'t match.');
        } else if (password.length < 6) {
          self.set('errorMessage', 'Password is too short (minimum is 6 characters).');
        } else {
          Ember.$.ajax({
            type: "PUT",
            url: _environment.default.apiHost + '/users/password',
            data: {
              token: this.get('token'),
              password: password
            },
            success: function success(results) {
              var _this = this;

              self.set('errorMessage', null);
              self.set('successMessage', 'Your password has been reset.');
              self.get('session').authenticate('authenticator:devise', results.email, password).catch(function (reason) {
                _this.set('errorMessage', reason.error || reason);
              });
            },
            error: function error(results) {
              self.set('errorMessage', results.responseJSON.error);
            },
            dataType: 'json'
          });
        }
      }
    }
  });
});