define('apco-web/components/site-header', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        currentUser: Ember.inject.service('current-user'),
        actions: {
            closeLater: function closeLater(dropdown) {
                var _this = this;

                this.closeTimer = Ember.run.later(function () {
                    _this.closeTimer = null;
                    dropdown.actions.close();
                }, 300);
            },
            openUserDropdown: function openUserDropdown(dropdown) {
                if (this.closeTimer) {
                    Ember.run.cancel(this.closeTimer);
                    this.closeTimer = null;
                } else {
                    dropdown.actions.open();
                }
            }
        }
    });
});