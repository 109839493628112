define('apco-web/controllers/projects', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        init: function init() {
            this._super.apply(this, arguments);
            // define arrays here to avoid avoid-leaking-state-in-ember-objects warning
            this.sortOrder = ['name']; // or 'created_date:desc'
            this.sortFields = [{ field: 'name', label: 'Name' }, { field: 'created_date:desc', label: 'Date' }];
        },

        isDisabled: Ember.computed('name', function () {
            var name = this.get('name');
            return typeof name === "undefined";
        }),
        filterText: '',
        displayMode: 'grid', // or 'list'
        sortedProjects: Ember.computed.sort('model.projects', 'sortOrder'),
        projects: Ember.computed('filterText', 'sortedProjects', function () {
            var items = this.get('sortedProjects');
            var search = this.get('filterText').toLowerCase();

            if (Ember.isEmpty(search)) {
                return items;
            }

            return items.filter(function (item) {
                var name = item.get('name').toLowerCase();
                if (name.indexOf(search) !== -1) {
                    return true;
                }
                return false;
            });
        }),
        actions: {
            closeLater: function closeLater(dropdown) {
                var _this = this;

                this.closeTimer = Ember.run.later(function () {
                    _this.closeTimer = null;
                    dropdown.actions.close();
                }, 300);
            },
            openSortDropdown: function openSortDropdown(dropdown) {
                if (this.closeTimer) {
                    Ember.run.cancel(this.closeTimer);
                    this.closeTimer = null;
                } else {
                    dropdown.actions.open();
                }
            },
            setDisplayMode: function setDisplayMode(newMode) {
                this.set('displayMode', newMode);
            },
            setSortOrder: function setSortOrder(dropdown, newOrder) {
                dropdown.actions.close();
                this.set('sortOrder', [newOrder]);
            }
        }
    });
});