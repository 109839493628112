define('apco-web/components/signup-form', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        actions: {
            submit: function submit() {
                this.get('triggerSave')(this.get('user'));
            }
        }
    });
});