define('apco-web/routes/change-password', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        title: "Change Password",
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        errorMessage: '',
        successMessage: ''
    });
});