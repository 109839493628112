define('apco-web/models/color', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        long_name: _emberData.default.attr('string'),
        color: _emberData.default.attr('string'),
        is_pantone: _emberData.default.attr(),
        backer_only: _emberData.default.attr(),
        category: _emberData.default.attr('string'),
        backer_image: _emberData.default.belongsTo('backer_image'),
        sort: _emberData.default.attr(),
        created_date: _emberData.default.attr('date'),
        is_available_arcadia: _emberData.default.attr(),
        is_available_fullview: _emberData.default.attr(),
        is_available_elevate: _emberData.default.attr(),
        fullName: Ember.computed('name', 'long_name', function () {
            var long_name = this.get('long_name');
            if (long_name && long_name.length > 0) {
                return long_name;
            }
            var name = this.get('name');
            return name;
        }),
        colorStyle: Ember.computed('color', function () {
            return Ember.String.htmlSafe("background-color: #" + this.get('color'));
        }),
        backerStyle: Ember.computed('backer_image.image', function () {
            if (!this.get('color')) {
                if (this.get('category') == 'Custom') {
                    return Ember.String.htmlSafe("background-image: url(" + this.get('backer_image.image.url') + ")");
                }
                return Ember.String.htmlSafe("background-image: url(/assets/images/" + this.get('backer_image.filename') + ")");
            }
            return '';
        }),
        svgStyle: Ember.computed('color', function () {
            var color = this.get('color');
            if (color && color.length > 0) {
                return Ember.String.htmlSafe("fill: #" + color);
            }
            return Ember.String.htmlSafe("fill: url(#color" + this.get('id') + ")");
        }),
        svgStyleRWood: Ember.computed('color', function () {
            // use this for arcadia and fullview backer A & B, where wood textures are rotated
            var color = this.get('color');
            if (color && color.length > 0) {
                // solid colors remain the same as svgStyle()
                return Ember.String.htmlSafe("fill: #" + color);
            }
            if (this.get('category') == 'wood') {
                // use rotated woodgrain
                return Ember.String.htmlSafe("fill: url(#color-r" + this.get('id') + ")");
            }
            // not wood, so don't rotate
            return Ember.String.htmlSafe("fill: url(#color" + this.get('id') + ")");
        })
    });
});