define('apco-web/controllers/forgot-password', ['exports', 'apco-web/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            forgotPassword: function forgotPassword() {
                var self = this;
                Ember.$.ajax({
                    type: "POST",
                    url: _environment.default.apiHost + '/users/password',
                    data: { email: this.get('email') },
                    success: function success() /*results*/{
                        self.set('errorMessage', null);
                        self.set('successMessage', 'An email has been set with reset instructions.');
                    },
                    error: function error() /*results*/{
                        self.set('successMessage', null);
                        self.set('errorMessage', 'User not found.');
                    },
                    dataType: 'json'
                });
            }
        }
    });
});