define('apco-web/routes/concepts/new', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        title: "New Concept",
        errorMessage: "",
        spinner: Ember.inject.service('spinner'),
        model: function model() {
            return Ember.RSVP.hash({
                project: this.get('store').findRecord('project', this.paramsFor('concepts').project_id),
                concept_types: this.get('store').findAll('concept_type'),
                colors: this.get('store').findAll('color'),
                concept: { name: '', concept_type_id: '' },
                fonts: this.get('store').findAll('font'),
                backer_shapes: this.get('store').findAll('backer_shape'),
                industries: this.get('store').findAll('industry')
            });
        },

        actions: {
            closeModal: function closeModal(project_id) {
                this.transitionTo('concepts', { queryParams: { project_id: project_id } });
            },
            createConcept: function createConcept(project_id, data) {
                if (data.name.length == 0) {
                    this.controller.set('errorMessage', 'Please enter a concept name.');
                    return;
                }
                if (data.concept_type_id.length == 0) {
                    this.controller.set('errorMessage', 'Please select a concept type.');
                    return;
                }
                this.get('spinner').show('concept-spinner');
                var that = this;
                this.model().then(function (m) {
                    var white = null;
                    var black = null;
                    m.colors.forEach(function (color) {
                        if (color.get('name') === 'A01') {
                            white = color;
                        } else if (color.get('name') === 'A02') {
                            black = color;
                        }
                    });

                    if (white && black) {
                        var concept = that.store.createRecord('concept', {
                            name: data.name,
                            backer_shape: that.store.peekRecord('backer_shape', 1),
                            concept_type: that.store.peekRecord('concept_type', data.concept_type_id),
                            font: that.store.peekRecord('font', 1),
                            industry: that.store.peekRecord('industry', 1),
                            project: that.store.peekRecord('project', project_id),
                            backer_colora: white,
                            backer_colorb: white,
                            frame_color: white,
                            divider_color: white,
                            header_bg_color: white,
                            header_text_color: black,
                            insert_bg_color: white,
                            insert_text_color: black,
                            paper_bg_color: white,
                            paper_text_color: black,
                            created_date: new Date()
                        });
                        concept.save().then(function (c) {
                            return that.transitionTo('design', project_id, c.id);
                        });
                    } else {
                        alert('Could not create your concept due to an error. Please try again later.');
                    }
                });
            }
        }
    });
});