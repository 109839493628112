define('apco-web/authorizers/devise', ['exports', 'ember-simple-auth/authorizers/devise'], function (exports, _devise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _devise.default.extend({
    authorize: function authorize(data, header) {
      this._super(data, header);

      // This is required to get authorization headers in the format being expected by Rails/simple_token_authentication
      header('X-User-Token', data.token);
      header('X-User-Email', data.email);
    }
  });
});