define('apco-web/mirage/factories/backer-shape', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberCliMirage.default.Factory.extend({
        name: function name(i) {
            if (i < 10) {
                return ['Arc 1', 'Arc 2', 'Arc 4', 'Scroll 1', 'Scroll 2', 'Scroll 4', 'Ortho 1', 'Ortho 2', 'Curve 1', 'None'][i];
            }
            return 'Backer Shape ' + i;
        },
        shape: function shape(i) {
            return 'shape' + (i + 1).toString() + '.svg';
        },
        created_date: function created_date() {
            return [new Date(2018, 0, 5), new Date(2017, 8, 18), new Date(2017, 11, 3), new Date(2018, 0, 27)][Math.floor(Math.random() * 4)];
        }
    });
});