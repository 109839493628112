define('apco-web/components/radio-button-control', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'input',
        type: 'radio',
        // value      - required
        // groupValue - required
        // autofocus  - boolean
        // disabled   - optional
        // name       - optional
        // required   - optional
        // radioClass - string
        // radioId    - string
        // tabindex   - number
        // ariaLabelledby - string
        defaultLayout: null, // ie8 support
        attributeBindings: ['autofocus', 'checked', 'disabled', 'name', 'required', 'tabindex', 'type', 'value', 'ariaLabelledby:aria-labelledby'],
        checked: Ember.computed('groupValue', 'value', function () {
            return Ember.isEqual(this.get('groupValue'), this.get('value'));
        }).readOnly(),
        change: function change() {
            var value = this.get('value');
            if (!Ember.isEqual(value, this.get('groupValue'))) {
                this.sendAction('changed', value);
            }
        }
    });
});