define('apco-web/controllers/change-password', ['exports', 'apco-web/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        currentUser: Ember.inject.service('current-user'),
        session: Ember.inject.service('session'),
        spinner: Ember.inject.service('spinner'),
        actions: {
            changePassword: function changePassword() {
                this.get('spinner').show('change-password', { timeout: 8000 });
                var self = this;
                var password = this.get('currentPassword');
                var newPassword = this.get('newPassword');
                var confirmPassword = this.get('confirmPassword');
                if (newPassword != confirmPassword) {
                    self.set('errorMessage', 'Passwords don\'t match.');
                    self.get('spinner').hide('change-password');
                } else if (!newPassword || newPassword.length < 6) {
                    self.set('errorMessage', 'Password is too short (minimum is 6 characters).');
                    self.get('spinner').hide('change-password');
                } else {
                    var data = self.get('session.data.authenticated');
                    Ember.$.ajax({
                        headers: {
                            'X-User-Token': data.token,
                            'X-User-Email': data.email
                        },
                        type: "POST",
                        url: _environment.default.apiHost + '/users/update_password',
                        data: { current_password: password, password: newPassword,
                            password_confirmation: confirmPassword },
                        success: function success() /*results*/{
                            self.get('spinner').hide('change-password');
                            self.set('currentPassword', '');
                            self.set('newPassword', '');
                            self.set('confirmPassword', '');
                            self.set('errorMessage', null);
                            self.set('successMessage', 'Your password has been updated.');
                            setTimeout(function () {
                                self.set('successMessage', null);
                            }, 10000);
                            self.transitionToRoute('projects');
                        },
                        error: function error() /*results*/{
                            self.get('spinner').hide('change-password');
                            self.set('errorMessage', 'Password was not changed.');
                            self.set('successMessage', null);
                            setTimeout(function () {
                                self.set('errorMessage', null);
                            }, 10000);
                        },
                        dataType: 'json'
                    });
                }
            }
        }
    });
});