define('apco-web/tests/mirage/mirage.lint-test', [], function () {
  'use strict';

  QUnit.module('ESLint | mirage');

  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/backer-image.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/backer-image.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/backer-shape.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/backer-shape.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/color.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/color.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/concept-type.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/concept-type.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/concept.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/concept.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/font.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/font.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/industry.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/industry.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/project.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/project.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });
});