define('apco-web/routes/concepts', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        title: "Concepts",
        model: function model(params) {
            return Ember.RSVP.hash({
                project: this.get('store').findRecord('project', params.project_id),
                colors: this.get('store').findAll('color')
            });
        }
    });
});