define('apco-web/components/design-container', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        activePanel: '',
        backerTitle: Ember.computed('concept.concept_type.name', function () {
            var concept_type = this.get('concept.concept_type.name');
            return concept_type == "Elevate" ? "Header Shape" : "Backer Shape";
        }),
        actions: {
            selectPanel: function selectPanel(panel) {
                if (panel == this.activePanel) {
                    this.set('activePanel', '');
                } else {
                    this.set('activePanel', panel);
                }
            },
            colorChanged: function colorChanged(name, newValue) {
                this.sendAction('colorChanged', name, newValue);
            },
            changedBackerShape: function changedBackerShape(name, newValue) {
                this.sendAction('changedBackerShape', name, newValue);
            },
            uploadImage: function uploadImage(colorProperty, file) {
                this.sendAction('uploadImage', colorProperty, file);
            },
            deleteImage: function deleteImage(color) {
                this.sendAction('deleteImage', color);
            }
        }
    });
});