define('apco-web/routes/concepts/edit', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        title: "Edit Concept",
        model: function model(params) {
            return this.get('store').findRecord('concept', params.concept_id);
        },

        actions: {
            closeModal: function closeModal(concept) {
                concept.rollbackAttributes();
                this.transitionTo('concepts');
            },
            saveConcept: function saveConcept(concept) {
                var _this = this;

                concept.save().then(function () {
                    return _this.transitionTo('concepts');
                });
            },
            deleteConcept: function deleteConcept(concept) {
                var _this2 = this;

                if (confirm('Are you sure you want to delete this concept?')) {
                    concept.destroyRecord().then(function () {
                        return _this2.transitionTo('concepts');
                    });
                }
            }
        }
    });
});