define('apco-web/controllers/concepts', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        init: function init() {
            this._super.apply(this, arguments);
            // define arrays here to avoid avoid-leaking-state-in-ember-objects warning
            this.sortOrder = ['name']; // or 'created_date:desc'
            this.sortFields = [{ field: 'name', label: 'Name' }, { field: 'created_date:desc', label: 'Date' }];
        },

        filteredConcepts: Ember.computed('model.project', function () {
            return this.get('model.project.concepts');
        }),
        sortedConcepts: Ember.computed.sort('filteredConcepts', 'sortOrder'),
        actions: {
            closeLater: function closeLater(dropdown) {
                var _this = this;

                this.closeTimer = Ember.run.later(function () {
                    _this.closeTimer = null;
                    dropdown.actions.close();
                }, 300);
            },
            openSortDropdown: function openSortDropdown(dropdown) {
                if (this.closeTimer) {
                    Ember.run.cancel(this.closeTimer);
                    this.closeTimer = null;
                } else {
                    dropdown.actions.open();
                }
            },
            setSortOrder: function setSortOrder(dropdown, newOrder) {
                dropdown.actions.close();
                this.set('sortOrder', [newOrder]);
            }
        }
    });
});