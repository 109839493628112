define('apco-web/routes/forgot-password', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
        title: "Forgot Password",
        model: function model() {
            return this.store.createRecord('user', { email: '', fullname: '', company: '', phone: '', created_date: new Date() });
        }
    });
});