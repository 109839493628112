define('apco-web/models/backer-image', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        image: _emberData.default.attr(),
        filename: _emberData.default.attr(),
        height: _emberData.default.attr(),
        width: _emberData.default.attr(),
        color: _emberData.default.belongsTo('color'),
        patternHeight: Ember.computed('width', 'height', function () {
            // a percentage such as 100% or 125%
            var height = this.get('height');
            var width = this.get('width');
            if (width > height) {
                return Ember.String.htmlSafe('100%');
            } else {
                return Ember.String.htmlSafe(this.get('width') / Math.floor(this.get('height')) * 100 + '%');
            }
        }),
        patternWidth: Ember.computed('width', 'height', function () {
            // a percentage such as 100% or 125%
            var height = this.get('height');
            var width = this.get('width');
            if (width > height) {
                return Ember.String.htmlSafe(this.get('width') / Math.floor(this.get('height')) * 100 + '%');
            } else {
                return Ember.String.htmlSafe('100%');
            }
        }),
        patternImageHeight: Ember.computed('width', 'height', function () {
            // floating-point number such as 1, or 1.25
            var height = this.get('height');
            var width = this.get('width');
            if (width > height) {
                return Ember.String.htmlSafe('1');
            } else {
                return Ember.String.htmlSafe(this.get('height') / Math.floor(this.get('width')));
            }
        }),
        patternImageWidth: Ember.computed('width', 'height', function () {
            // floating-point number such as 1, or 1.25
            var height = this.get('height');
            var width = this.get('width');
            if (width > height) {
                return Ember.String.htmlSafe(this.get('width') / Math.floor(this.get('height')));
            } else {
                return Ember.String.htmlSafe('1');
            }
        })
    });
});