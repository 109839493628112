define('apco-web/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        fullname: _emberData.default.attr('string'),
        company: _emberData.default.attr('string'),
        phone: _emberData.default.attr('string'),
        email: _emberData.default.attr('string'),
        password: _emberData.default.attr(),
        reset_password_token: _emberData.default.attr(),
        reset_password_sent_at: _emberData.default.attr(),
        remember_created_at: _emberData.default.attr(),
        sign_in_count: _emberData.default.attr(),
        current_sign_in_at: _emberData.default.attr(),
        last_sign_in_at: _emberData.default.attr(),
        current_sign_in_ip: _emberData.default.attr(),
        last_sign_in_ip: _emberData.default.attr(),
        confirmation_token: _emberData.default.attr(),
        confirmed_at: _emberData.default.attr(),
        confirmation_sent_at: _emberData.default.attr(),
        unconfirmed_email: _emberData.default.attr(),
        failed_attempts: _emberData.default.attr(),
        unlock_token: _emberData.default.attr(),
        locked_at: _emberData.default.attr(),
        created_date: _emberData.default.attr('date'),
        concepts: _emberData.default.hasMany('project')
    });
});