define('apco-web/models/concept', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        industry: _emberData.default.belongsTo('industry', { inverse: null }),
        font: _emberData.default.belongsTo('font', { inverse: null }),
        concept_type: _emberData.default.belongsTo('concept_type', { inverse: null }),
        backer_shape: _emberData.default.belongsTo('backer_shape', { inverse: null }),
        backer_colora: _emberData.default.belongsTo('color', { inverse: null }),
        backer_colorb: _emberData.default.belongsTo('color', { inverse: null }),
        frame_color: _emberData.default.belongsTo('color', { inverse: null }),
        divider_color: _emberData.default.belongsTo('color', { inverse: null }),
        header_bg_color: _emberData.default.belongsTo('color', { inverse: null }),
        header_text_color: _emberData.default.belongsTo('color', { inverse: null }),
        insert_bg_color: _emberData.default.belongsTo('color', { inverse: null }),
        insert_text_color: _emberData.default.belongsTo('color', { inverse: null }),
        paper_bg_color: _emberData.default.belongsTo('color', { inverse: null }),
        paper_text_color: _emberData.default.belongsTo('color', { inverse: null }),
        project: _emberData.default.belongsTo('project', { inverseOf: 'concepts' }),
        created_date: _emberData.default.attr('date'),
        hasBackerA: Ember.computed('concept_type', 'backer_shape', function () {
            var backer_shape = this.get('backer_shape.name');
            return backer_shape != 'None';
        }),
        hasBackerB: Ember.computed('concept_type', 'backer_shape', function () {
            var backer_shape = this.get('backer_shape.name');
            return backer_shape == 'Arc 4' || backer_shape == 'Scroll 4';
        }),
        usedColors: Ember.computed('backer_colora', 'backer_colorb', 'frame_color', 'divider_color', 'header_bg_color', 'header_text_color', 'insert_bg_color', 'insert_text_color', 'paper_bg_color', 'paper_text_color', function () {
            return [this.get('backer_colora'), this.get('backer_colorb'), this.get('frame_color'), this.get('divider_color'), this.get('header_bg_color'), this.get('header_text_color'), this.get('insert_bg_color'), this.get('insert_text_color'), this.get('paper_bg_color'), this.get('paper_text_color')];
        }),
        backerName: Ember.computed('concept_type.name', 'backer_shape.name', function () {
            var concept_type = this.get('concept_type.name');
            var backer_shape = this.get('backer_shape.name');
            if (concept_type == 'Elevate') {
                if (backer_shape == 'Arc 1') return 'Arc';
                if (backer_shape == 'Scroll 1') return 'Scroll';
                if (backer_shape == 'Ortho 1') return 'Rect';
                if (backer_shape == 'Curve 1') return 'Curve';
            }
            return backer_shape;
        })
    });
});