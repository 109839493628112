define('apco-web/models/backer-shape', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        shape: _emberData.default.attr('string'),
        sortID: Ember.computed('id', function () {
            return Number(this.get('id'));
        })
    });
});