define('apco-web/mirage/factories/industry', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberCliMirage.default.Factory.extend({
        name: function name(i) {
            if (i < 3) {
                return ['Corporate', 'Healthcare', 'Education'][i];
            }
            return 'Industry ' + i;
        },
        created_date: function created_date() {
            return [new Date(2018, 0, 5), new Date(2017, 8, 18), new Date(2017, 11, 3), new Date(2018, 0, 27)][Math.floor(Math.random() * 4)];
        }
    });
});