define('apco-web/mirage/config', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    exports.default = function () {
        this.namespace = '/';

        //http://www.ember-cli-mirage.com/docs/v0.3.x/shorthands/

        this.get('http://localhost:3000/backer_images');
        this.post('http://localhost:3000/backer_images');
        this.get('http://localhost:3000/backer_images/:id');
        this.patch('http://localhost:3000/backer_images/:id'); // or this.put
        //this.del('http://localhost:3000/backer_images/:id');

        this.get('http://localhost:3000/backer_shapes');
        this.get('http://localhost:3000/backer_shapes/:id');

        this.get('http://localhost:3000/concept_types');
        this.get('http://localhost:3000/concept_types/:id');

        this.get('http://localhost:3000/colors');
        this.post('http://localhost:3000/colors');
        this.get('http://localhost:3000/colors/:id');
        this.patch('http://localhost:3000/colors/:id'); // or this.put
        //this.del('http://localhost:3000/colors/:id');

        //this.passthrough('http://localhost:3000/concepts', ['get', 'post']);
        this.passthrough('http://localhost:3000/concepts');
        this.post('http://localhost:3000/concepts');
        this.passthrough('http://localhost:3000/concepts/:id'); //, ['get', 'patch', 'del']);
        this.patch('http://localhost:3000/concepts/:id'); // or this.put
        this.del('http://localhost:3000/concepts/:id');

        this.get('http://localhost:3000/industries');
        this.get('http://localhost:3000/industries/:id');

        //this.get('http://localhost:3000/projects');
        this.passthrough('http://localhost:3000/projects');
        this.post('http://localhost:3000/projects');
        //this.passthrough('http://localhost:3000/projects', ['get', 'post']);
        this.passthrough('http://localhost:3000/projects/:id'); //, ['get', 'patch', 'del']);
        this.patch('http://localhost:3000/projects/:id'); // or this.put
        this.del('http://localhost:3000/projects/:id');

        this.get('http://localhost:3000/fonts');
        this.get('http://localhost:3000/fonts/:id');

        this.post('http://localhost:3000/users');
        this.passthrough('http://localhost:3000/users/:id');
        this.patch('http://localhost:3000/users/:id'); // or this.put
    };
});