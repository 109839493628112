define('apco-web/components/radio-button-label', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        // value - passed in, required, the value for this radio button
        // groupValue - passed in, required, the currently selected value
        // optionally passed in:
        // disabled - boolean
        // required - boolean
        // name - string
        // radioClass - string
        // radioId - string
        // ariaLabelledby - string
        // polyfill hasBlock for ember versions < 1.13
        hasBlock: Ember.computed.bool('template').readOnly(),
        joinedClassNames: Ember.computed('classNames', function () {
            var classNames = this.get('classNames');
            if (classNames && classNames.length && classNames.join) {
                return classNames.join(' ');
            }
            return classNames;
        }),
        checked: Ember.computed('groupValue', 'value', function () {
            return Ember.isEqual(this.get('groupValue'), this.get('value'));
        }).readOnly(),
        actions: {
            radioChanged: function radioChanged(newValue) {
                this.sendAction('changed', this.get('name'), newValue);
            }
        }
    });
});