define('apco-web/controllers/signup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    spinner: Ember.inject.service('spinner'),
    actions: {
      save: function save(user) {
        var _this = this;

        this.get('spinner').show('signup-spinner');
        var newUser = user;
        newUser.save().catch(function (error) {
          _this.get('spinner').hide('signup-spinner');
          _this.set('errorMessage', 'This email address has already been registered.');
          throw error;
        }).then(function () {
          _this.get('session').authenticate('authenticator:devise', newUser.get('email'), newUser.get('password')).catch(function (reason) {
            _this.set('errorMessage', reason.error || reason);
          });
        });
      }
    }
  });
});