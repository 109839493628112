define('apco-web/app', ['exports', 'apco-web/resolver', 'ember-load-initializers', 'apco-web/config/environment'], function (exports, _resolver, _emberLoadInitializers, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var search = window.location.search;
  if (search && search.length > 0 && /^\/projects\/[0-9]+\/concepts\/[0-9]+\/export$/i.test(window.location.pathname)) {
    var pairs = search.substring(1).split('&');
    var token, userId, email;
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=');
      if (pair.length == 2) {
        if (pair[0] === 'token') {
          token = pair[1];
        } else if (pair[0] === 'user_id') {
          userId = pair[1];
        } else if (pair[0] === 'email') {
          email = pair[1];
        }
      }
    }

    if (token && userId && email) {
      localStorage['ember_simple_auth-session'] = JSON.stringify({ "authenticated": { "authenticator": "authenticator:devise", "user_id": userId, "email": email, "token": token } });
    }
  }

  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;


  Ember.Controller.reopen({
    rootURL: _environment.default.rootURL,
    ENV: _environment.default
  });

  //import Component from '@ember/component';
  // /*Ember.*/Component.reopen({
  //   rootUrl: config.rootURL,
  //   ENV: config
  // });
});