define('apco-web/controllers/design/industry', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            changedIndustry: function changedIndustry(radio_name, newValue) {
                // TODO: CREATE AN UNDO STEP
                var concept = this.get('model.parent.concept');
                var industries = this.get('model.industries');
                var newIndustry = industries.filter(function (industry) {
                    var name = industry.get('name');
                    return 0 == name.indexOf(newValue);
                });
                concept.set('industry', newIndustry[0]);
                concept.save();
                this.transitionToRoute('design', { queryParams: { project_id: concept.projectId, concept_id: concept.id } });
            }
        }
    });
});