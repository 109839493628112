define('apco-web/routes/design/edit-concept', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        title: "Edit Concept",
        templateName: 'concepts/edit',
        model: function model() {
            return this.get('store').findRecord('concept', this.paramsFor('design').concept_id);
        },

        actions: {
            closeModal: function closeModal(concept) {
                concept.rollbackAttributes();
                this.transitionTo('design', concept.get('project.id'), concept.get('id'));
            },
            saveConcept: function saveConcept(concept) {
                var _this = this;

                concept.save().then(function () {
                    return _this.transitionTo('design', concept.get('project.id'), concept.get('id'));
                });
            },
            deleteConcept: function deleteConcept(concept) {
                if (confirm('Are you sure you want to delete this concept?')) {
                    var project_id = concept.get('project.id');
                    this.transitionTo('concepts', project_id);
                    concept.destroyRecord();
                }
            }
        }
    });
});