define('apco-web/controllers/export', ['exports', 'npm:pdfkit', 'npm:svg-to-pdfkit', 'npm:blob-stream'], function (exports, _npmPdfkit, _npmSvgToPdfkit, _npmBlobStream) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['filename', 'mode'],
        init: function init() {
            this._super.apply(this, arguments);

            Ember.run.scheduleOnce('afterRender', this, function () {
                var mode = this.get('mode');
                if (mode === 'overview') {
                    this.pdfOverview();
                } else if (mode === 'screenshot') {
                    this.screenshot();
                }
            });
        },
        pdfOverview: function pdfOverview() {
            var fileName = this.get('filename');
            // use later() so that spinner can be shown in page immediately
            Ember.run.later(function () {
                var x = 0;
                var y = 0;
                var pagewidth = 1024; // letter: 612x792
                var pageheight = 612;
                var options = {
                    layout: 'landscape',
                    useCSS: true,
                    assumePt: true,
                    preserveAspectRatio: false,
                    width: pagewidth,
                    height: pageheight,
                    imageCallback: function imageCallback(filename) {
                        var imageData = null;
                        var xhr = new XMLHttpRequest();
                        // mime type prevents character conversion of binary data
                        xhr.overrideMimeType('text/plain; charset=x-user-defined');
                        // disable cache for S3 urls because cached copy does not include CORS header
                        xhr.open("GET", filename, false);
                        if (filename.indexOf('s3.amazonaws.com') != -1) {
                            xhr.setRequestHeader('cache-control', 'no-cache, must-revalidate, post-check=0, pre-check=0');
                            xhr.setRequestHeader('cache-control', 'max-age=0');
                            xhr.setRequestHeader('expires', '0');
                            xhr.setRequestHeader('expires', 'Tue, 01 Jan 1980 1:00:00 GMT');
                            xhr.setRequestHeader('pragma', 'no-cache');
                        }
                        xhr.onreadystatechange = function () {
                            if (xhr.readyState === 4) {
                                if (xhr.status === 200 || xhr.status == 0) {
                                    var theArray = new ArrayBuffer(xhr.response.length);
                                    var bufView = new Uint8Array(theArray);
                                    for (var i = 0, strLen = xhr.response.length; i < strLen; i++) {
                                        bufView[i] = xhr.response.charCodeAt(i);
                                    }
                                    imageData = theArray;
                                }
                            }
                        };
                        xhr.send(null);
                        return imageData;
                    }
                };
                var doc = new _npmPdfkit.default({ compress: true, layout: 'landscape', size: 'legal' });
                var svg = document.getElementById("export-svg");
                (0, _npmSvgToPdfkit.default)(doc, svg, x, y, options);
                var stream = doc.pipe((0, _npmBlobStream.default)());
                stream.on('finish', function () {
                    var blob = stream.toBlob('application/pdf');
                    if (navigator.msSaveOrOpenBlob) {
                        navigator.msSaveOrOpenBlob(blob, fileName);
                    } else {
                        // For other browsers:
                        // Create a link pointing to the ObjectURL containing the blob.
                        var data = window.URL.createObjectURL(blob);
                        var link = document.createElement('a');
                        link.href = data;
                        link.download = fileName;
                        link.click();
                        setTimeout(function () {
                            // For Firefox it is necessary to delay revoking the ObjectURL
                            window.URL.revokeObjectURL(data);
                        }, 100);
                    }
                });
                doc.end();
            }, 500);
        },
        screenshot: function screenshot() {
            var _this = this;

            var fileName = this.get('filename');

            // use later() so that spinner can be shown in page immediately
            Ember.run.later(function () {
                var type = _this.get('model.concept.concept_type.name');
                var x = 0;
                var y = 0;
                // default pageWidth was 612;
                // compensate for the whitespace on the right side of the SVG by adjusting the pageWidth
                var pagewidth = 660; // Arcadia
                if (type == 'Elevate') {
                    pagewidth = 745;
                }
                if (type == 'FullView') {
                    pagewidth = 686;
                }
                var pageheight = 792;
                var options = {
                    layout: 'portrait',
                    useCSS: true,
                    assumePt: true,
                    preserveAspectRatio: false,
                    width: pagewidth,
                    height: pageheight,
                    imageCallback: function imageCallback(filename) {
                        var imageData = null;
                        var xhr = new XMLHttpRequest();
                        // mime type prevents character conversion of binary data
                        xhr.overrideMimeType('text/plain; charset=x-user-defined');
                        xhr.open("GET", filename, false);
                        if (filename.indexOf('s3.amazonaws.com') != -1) {
                            xhr.setRequestHeader('cache-control', 'no-cache, must-revalidate, post-check=0, pre-check=0');
                            xhr.setRequestHeader('cache-control', 'max-age=0');
                            xhr.setRequestHeader('expires', '0');
                            xhr.setRequestHeader('expires', 'Tue, 01 Jan 1980 1:00:00 GMT');
                            xhr.setRequestHeader('pragma', 'no-cache');
                        }
                        xhr.onreadystatechange = function () {
                            if (xhr.readyState === 4) {
                                if (xhr.status === 200 || xhr.status == 0) {
                                    var theArray = new ArrayBuffer(xhr.response.length);
                                    var bufView = new Uint8Array(theArray);
                                    for (var i = 0, strLen = xhr.response.length; i < strLen; i++) {
                                        bufView[i] = xhr.response.charCodeAt(i);
                                    }
                                    imageData = theArray;
                                }
                            }
                        };
                        xhr.send(null);
                        return imageData;
                    }
                };
                var doc = new _npmPdfkit.default({ compress: true, layout: 'portrait' });
                var svg = document.getElementById("design-svg");
                (0, _npmSvgToPdfkit.default)(doc, svg, x, y, options);
                var stream = doc.pipe((0, _npmBlobStream.default)());
                stream.on('finish', function () {
                    var blob = stream.toBlob('application/pdf');
                    if (navigator.msSaveOrOpenBlob) {
                        navigator.msSaveOrOpenBlob(blob, fileName);
                    } else {
                        // For other browsers: create a link pointing to the ObjectURL containing the blob.
                        var data = window.URL.createObjectURL(blob);
                        var link = document.createElement('a');
                        link.href = data;
                        link.download = fileName;
                        link.click();
                        setTimeout(function () {
                            // For Firefox it is necessary to delay revoking the ObjectURL
                            window.URL.revokeObjectURL(data);
                        }, 100);
                    }
                });
                doc.end();
            }, 500);
        },

        actions: {}
    });
});