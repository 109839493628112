define('apco-web/routes/design/industry', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        title: "Edit Project",
        model: function model() {
            return Ember.RSVP.hash({
                parent: this.modelFor('design'),
                industries: this.get('store').findAll('industry')
            });
        },

        actions: {
            closeModal: function closeModal(project_id, concept_id) {
                this.transitionTo('design', { queryParams: { project_id: project_id, concept_id: concept_id } });
            }
        }
    });
});