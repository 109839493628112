define('apco-web/routes/concepts/edit-project', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        title: "Edit Project",
        templateName: 'projects/edit',
        model: function model() {
            return this.get('store').findRecord('project', this.paramsFor('concepts').project_id);
        },

        actions: {
            closeModal: function closeModal(project) {
                project.rollbackAttributes();
                this.transitionTo('concepts', project.get('id'));
            },
            saveProject: function saveProject(project) {
                var _this = this;

                project.save().then(function () {
                    return _this.transitionTo('concepts', project.get('id'));
                });
            },
            deleteProject: function deleteProject(project) {
                var _this2 = this;

                if (confirm('Are you sure you want to delete this project?')) {
                    // ensure the background reload of the project finishes before deleting or transitioning
                    this.get('store').findRecord('project', project.id, { reload: true }).then(function (project) {
                        return project.destroyRecord();
                    }).then(function () {
                        _this2.transitionTo('projects');
                    });
                }
            }
        }
    });
});