define('apco-web/adapters/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'apco-web/config/environment', 'ember-inflector'], function (exports, _emberData, _dataAdapterMixin, _environment, _emberInflector) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
        host: _environment.default.apiHost,
        namespace: '',
        authorizer: 'authorizer:devise',

        pathForType: function pathForType(type) {
            return (0, _emberInflector.pluralize)(Ember.String.underscore(type));
        }
    });
});