define('apco-web/controllers/design/font', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            changedFont: function changedFont(radio_name, newValue) {
                // TODO: CREATE AN UNDO STEP
                var concept = this.get('model.parent.concept');
                var fonts = this.get('model.fonts');
                var newFont = fonts.filter(function (font) {
                    var name = font.get('name');
                    return 0 == name.indexOf(newValue);
                });
                concept.set('font', newFont[0]);
                concept.save();
                this.transitionToRoute('design', { queryParams: { project_id: concept.projectId, concept_id: concept.id } });
            }
        }
    });
});