define('apco-web/mirage/scenarios/default', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (server) {
    /*
      Seed your development database using your factories.
      This data will not be loaded in your tests.
    */
    server.createList('color', 1073);
    server.createList('concept_type', 3);
    server.createList('industry', 3);
    server.createList('font', 3);
    server.createList('backer_shape', 10);
    server.createList('concept', 2, { project: server.create('project') });
    server.createList('concept', 3, { project: server.create('project') });
    server.createList('concept', 5, { project: server.create('project') });
  };
});