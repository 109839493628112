define('apco-web/helpers/backer-transform', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.backerTransform = backerTransform;
    function backerTransform(params /*, hash*/) {
        if (params.length === 0) {
            return undefined;
        }

        var path_width = params[0];
        var path_height = params[1];
        // sometimes wood backers are rotated 90°
        var swap_w_h = params[4];
        var img_width = params[swap_w_h ? 3 : 2];
        var img_height = params[swap_w_h ? 2 : 3];

        var image_ratio = img_width / img_height;
        var path_ratio = path_width / path_height;

        if (image_ratio > path_ratio) {
            // image touches top and bottom path edges, so offset left and right edges
            var width = path_ratio / image_ratio;
            var left = (1.0 - width) / 2.0;
            // min-x, min-y, width, height
            return Ember.String.htmlSafe(left + " 0 " + width + " 1");
        } else {
            // image touches left and right path edges, so offset top and bottom edges
            var height = image_ratio / path_ratio;
            var top = (1.0 - height) / 2.0;
            // min-x, min-y, width, height
            return Ember.String.htmlSafe("0 " + top + " 1 " + height);
        }
    }

    exports.default = Ember.Helper.helper(backerTransform);
});