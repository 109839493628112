define('apco-web/helpers/asset', ['exports', 'apco-web/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.asset = asset;
    function asset(params /*, hash*/) {
        if (params.length === 0) {
            return undefined;
        }

        var filename = params[0];
        var type = {
            css: '',
            js: 'js/',
            gif: 'images/',
            jpg: 'images/',
            png: 'images/',
            svg: 'images/'
        }[filename.split(".")[1]];

        return _environment.default.rootURL + 'assets/' + type + filename;
    }

    exports.default = Ember.Helper.helper(asset);
});