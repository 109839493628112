define('apco-web/models/project', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        user_id: _emberData.default.attr(),
        name: _emberData.default.attr('string'),
        notes: _emberData.default.attr('string'),
        created_date: _emberData.default.attr('date'),
        concepts: _emberData.default.hasMany('concept')
    });
});