define('apco-web/routes/signup', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
        title: "Sign Up",
        model: function model() {
            return this.store.createRecord('user', { email: '', fullname: '', company: '', phone: '', created_date: new Date() });
        },

        actions: {
            createUser: function createUser(user) {
                var _this = this;

                user.save().then(function () {
                    return _this.transitionTo('projects');
                });
            }
        }
    });
});