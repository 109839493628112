define('apco-web/routes/design', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        model: function model(params) {
            return Ember.RSVP.hash({
                project: this.get('store').findRecord('project', params.project_id),
                concept: this.get('store').findRecord('concept', params.concept_id),
                colors: this.get('store').findAll('color'),
                backer_shapes: this.get('store').findAll('backer_shape')
            });
        }
    });
});